<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("proposalsp.htitle") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">
                {{ $t("proposalsp.ptitle") }}
              </h6>
            </b-col>
            <!-- <b-col cols="6" class="text-right">
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="onTambah"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Tambah Data
              </button>
            </b-col> -->
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-4 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('proposalmp.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <select class="custom-select" v-model="filterPeriode">
                  <option value="">
                    {{ $t("proposalmp.filter.period") }}
                  </option>
                  <option v-for="per in periods" :key="per.id" :value="per.id">
                    {{ dateFr(per.startDate, "MM/YYYY") }} -
                    {{ dateFr(per.endDate, "MM/YYYY") }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 pl-0">
                <select class="custom-select" v-model="filterTSS">
                  <option value="">
                    {{ $t("proposalmp.filter.tss") }}
                  </option>
                  <option v-for="usr in dataTSS" :key="usr.id" :value="usr.id">
                    {{ usr.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 pl-0">
                <select class="custom-select" v-model="filterStatus">
                  <option
                    v-for="st in statusProposals"
                    :key="st.id"
                    :value="st"
                  >
                    {{ st }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Role">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="role in optionRole1"
                        :key="role.id"
                      >
                        <label class="form-radio-label">
                          <input
                            class="form-radio-item"
                            type="radio"
                            :value="role"
                            v-model="filterRole"
                          />
                          {{ role }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRole === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="requests"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('proposalsp.headers[0]'),
                  key: 'tss'
                },
                {
                  label: $t('proposalsp.headers[1]'),
                  key: 'period'
                },
                {
                  label: $t('proposalsp.headers[2]'),
                  key: 'createdAt'
                },
                {
                  label: $t('proposalsp.headers[3]'),
                  key: 'status'
                },
                {
                  label: $t('proposalsp.headers[4]'),
                  key: 'actions'
                }
              ]"
              sort-icon-left
              show-empty
              small
              responsive="true"
              class="simple-master-table"
            >
              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++index + (currPage - 1) * perPage }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(tss)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    {{ data.item.users.name }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(period)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    {{
                      `${dateFr(
                        data.item.periods.startDate,
                        "MMMM YYYY"
                      )} - ${dateFr(data.item.periods.endDate, "MMMM YYYY")}`
                    }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(createdAt)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    {{ dateFr(data.item.createdAt, "DD MMM YYYY") }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(status)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                >
                  <li class="list-inline-item">
                    <span class="badge" :class="handleBadge(data.item.status)">
                      {{ data.item.status }}
                    </span>
                  </li>
                  <br />
                </ul>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-eye-fill ri-lg ri-mid action-btn"
                      @click="onDetail(item.id)"
                    ></i>
                    <!-- <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i> -->
                  </div>
                </ul>
              </template>
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-matpro" size="lg" centered hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <section class="req-data">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <th>{{ $t("proposalmp.tss") }}</th>
                <td>:</td>
                <td>
                  {{ details.users.name }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("proposalmp.period") }}</th>
                <td>:</td>
                <td>
                  {{
                    `${dateFr(
                      details.periods.startDate,
                      "MMMM YYYY"
                    )} - ${dateFr(details.periods.endDate, "MMMM YYYY")}`
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("proposalmp.sp") }}</th>
                <td>:</td>
                <td>
                  {{ details.jumlahSP }}
                </td>
              </tr>
              <tr v-if="details.status === 'APPROVED'">
                <th>Approved By</th>
                <td>:</td>
                <td>
                  {{ details.approvedBys ? details.approvedBys.name : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <table class="table table-hover tbl-proposals">
            <thead>
              <tr>
                <th>#</th>
                <th v-for="hd in $t('proposalsp.pheaders')" :key="hd.id">
                  {{ hd }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in details.proposals" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.produks.brand }}</td>
                <td>{{ item.rencanaDemoplot }}</td>
                <td>{{ item.jumlahSample }}</td>
                <td>{{ item.kebutuhanSample }}</td>
                <td>{{ item.kebutuhanSampleApproved }}</td>
                <td>{{ item.kebutuhanSampleApprovedGudang }}</td>
                <td>
                  <div v-if="item.status === 'PROCESS'">
                    <span class="badge" :class="handleBadge(item.status)">
                      {{ item.status }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <!-- <section>
          <BaseInput></BaseInput>
        </section> -->
        <!-- <form @submit="onSubmit"> -->
        <!-- <BaseInput
            type="number"
            blabel="Stok"
            placeholder="Masukkan stok"
            v-model="forms.stock"
            :error="$v.forms.stock.$error"
            feedback="Stok tidak boleh kosong"
          /> -->
        <!-- </form> -->
        <section class="text-center" v-if="details.status === 'PROCESS'">
          <button
            class="btn btn-primary w-25 ssp-14 my-3 py-2"
            @click="publishPropsReq(details.id)"
          >
            {{ $t("proposalmp.deliver") }}
          </button>
        </section>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import { requiredIf } from "vuelidate/lib/validators";
import { Q_LIST_USERSEARCH } from "@/graphql/queries";
import JwtService from "@/core/services/jwt.service";
import {
  detailProposalReqSample,
  detailAuthorization,
  listMatproTypes,
  listProposalReq,
  listPeriods
} from "@/graphql/queries";
import {
  delProposalRequest,
  publishProposalRequest,
  publishDetailProposalPr
} from "@/graphql/mutations";
import {
  statusProposalType,
  inventoryType,
  RoleType
} from "@/graphql/enum.type";
import Axios from "axios";

export default {
  components: {
    Card
    // BaseInput
    // "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      userId: JwtService.getID(),
      fields: [
        {
          label: "#",
          key: "index"
        },
        {
          label: "Nama TSS",
          key: "tss"
        },
        {
          label: "Periode",
          key: "period"
        },
        {
          label: "Tgl. Pengajuan",
          key: "createdAt"
        },
        {
          label: "Status",
          key: "status"
        },
        {
          label: "Action",
          key: "actions"
        }
      ],

      requests: [],
      matproTypes: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterStatus: null,
      filterPeriode: "",
      filterTSS: "",

      modalTitle: "",
      isEdit: false,
      details: {},
      userAuthorization: {},
      approvalForm: [],
      dataTSS: [],
      periods: [],

      statusProposals: [
        statusProposalType.CHECKED,
        statusProposalType.APPROVED,
        statusProposalType.PROCESS,
        statusProposalType.DELIVERED,
        statusProposalType.RECEIVED,
        statusProposalType.DECLINED
      ],
      optionRole: [RoleType.TSS],
      inventoryTypes: [inventoryType.SAMPLE],

      forms: {
        matpro: "",
        produk: "",
        stock: null
      }
    };
  },
  validations: {
    forms: {
      matpro: {
        required: requiredIf(function() {
          return !this.isStockEdit;
        })
      },
      produk: {
        required: requiredIf(function() {
          return !this.isStockEdit;
        })
      },
      stock: {
        required: requiredIf(function() {
          return !this.isEdit;
        })
      }
    }
  },
  apollo: {
    listRequestProposal: {
      query: () => listProposalReq,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          tipe: this.inventoryTypes[0],
          periodeId: this.filterPeriode,
          status: this.filterStatus,
          userId: this.filterTSS,
          orderBy: "UPDATED_AT",
          sortBy: "DESC"
        };
      },
      debounce: 500,
      result({ data }) {
        this.requests = data.listRequestProposal.requestProposals;
        this.totalPages = data.listRequestProposal.totalPage;
        this.totalData = data.listRequestProposal.total;
      },
      error(e) {
        this.catchError(e);
      }
    },
    listJenisMaterialPromosi: {
      query: () => listMatproTypes,
      variables() {
        return {
          page: 1,
          limit: 999,
          keyword: "",
          orderBy: "MATPRO",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.matproTypes = data.listJenisMaterialPromosi.jenisrequests;
      }
    },
    detailUser: {
      query: () => detailAuthorization,
      variables() {
        return {
          id: this.userId
        };
      },
      result({ data }) {
        this.userAuthorization = data.detailUser.approvePropos;
      }
    },
    listTSS: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: "",
          role: this.optionRole[0],
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataTSS = data.listUser.users;
      }
    },
    listPeriode: {
      query: () => listPeriods,
      variables() {
        return {
          page: 1,
          limit: 999,
          orderBy: "YEAR",
          sortBy: "ASC"
        };
      },
      result({ data }) {
        this.periods = data.listPeriode.periods;
      },
      error(err) {
        this.catchError(err);
      }
    }
  },
  methods: {
    onTambah() {
      this.modalTitle = "Tambah Data Tanaman Baru";
      this.isEdit = false;
      this.resetForm();
      this.$bvModal.show("modal-matpro");
    },
    onDetail(id) {
      this.resetForm();
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .query({
              query: detailProposalReqSample,
              variables: {
                id: id
              }
            })
            .then(res => {
              this.details = res.data.detailRequestProposal;
              this.forms.id = id;
              this.modalTitle = `Detail Approved Sampel`;
              this.$bvModal.show("modal-matpro");
              resolve();
            });
        } catch (e) {
          this.errorAlert(e);
          reject(e);
        }
      });
    },
    // onEdit(data) {
    //   this.resetForm();
    //   this.modalTitle = `Edit ${data.jenisMatpros.matpro} - ${data.produks.brand}`;
    //   this.isEdit = true;

    //   this.forms.id = data.id;
    //   this.forms.matpro = data.jenisMatpros;
    //   this.forms.produk = data.produks;
    //   this.forms.stock = data.stock;
    //   this.$bvModal.show("modal-matpro");
    // },
    publishOneRequest(index, item, type) {
      this.approvalForm[index] = item;
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: publishDetailProposalPr,
              variables: {
                id: this.approvalForm[index].id,
                status: type,
                kebutuhanSampleApproved: this.approvalForm[index]
                  .kebutuhanSampleProcessed
              }
            })
            .then(() => {
              this.approvalForm[index].status === "PROCESS";
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    publishPropsReq(id) {
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .mutate({
              mutation: publishProposalRequest,
              variables: {
                id: id,
                status: this.statusProposals[3],
                userId: this.userId
              }
            })
            .then(() => {
              this.$apollo.queries.listRequestProposal.refetch();
              this.toastAlert("Proposal delivered");
              this.closeModal();
              resolve();
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    handleBadge(status) {
      switch (status) {
        case "CHECKED":
          return "badge-gray";
        case "APPROVED":
          return "badge-blue";
        case "PROCESS":
          return "badge-purple";
        case "DELIVERED":
          return "badge-purple-2";
        case "RECEIVED":
          return "badge-green";
        case "DECLINED":
          return "badge-red";
        default:
          break;
      }
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.forms = {};
      this.approvalForm = [];
      this.isEdit = false;
      this.isStockEdit = false;
      this.$v.$reset();
    },
    closeModal() {
      this.$bvModal.hide("modal-matpro");
      this.forms.matpro = "";
    },
    // handleTp(val) {
    //   switch (val) {
    //     case "SAFM":
    //       return "Support Activity FM";
    //     case "SAOD":
    //       return "Support Activity FM";
    //     default:
    //       break;
    //   }
    // },
    // SAFM: "SAFM",
    // SAOD: "SAOD",
    // SAFF: "SAFF",
    // DB: "DB",
    // EB: "EB",
    // KB: "KB",
    // PB: "PB"
    // async onSubmit(ev) {
    //   ev.preventDefault();
    //   this.$v.$touch();
    //   if (!this.$v.$error) {
    //     if (this.isEdit) {
    //       return new Promise((resolve, reject) => {
    //         const editMatpro = this.$apollo.mutate({
    //           mutation: updateMatpro,
    //           variables: {
    //             id: this.forms.id,
    //             jenisMatproId: this.forms.matpro ? this.forms.matpro.id : "",
    //             produkId: this.forms.produk ? this.forms.produk.id : ""
    //           }
    //         });
    //         if (resolve) {
    //           resolve(editMatpro);
    //         } else {
    //           reject(editMatpro);
    //         }
    //       })
    //         .then(response => {
    //           if (response) {
    //             this.toastAlert(this.$t("alert.edit"));
    //             this.closeModal();
    //             this.$apollo.queries.listRequestProposal.refetch();
    //           }
    //         })
    //         .catch(err => {
    //           this.$swal.fire({
    //             icon: "error",
    //             title: "Error!",
    //             text: err.message,
    //             width: 400,
    //             confirmButtonText: "OK"
    //           });
    //         });
    //     } else {
    //       return new Promise((resolve, reject) => {
    //         const addMatpro = this.$apollo.mutate({
    //           mutation: createMatpro,
    //           variables: {
    //             jenisMatproId: this.forms.matpro ? this.forms.matpro.id : "",
    //             produkId: this.forms.produk ? this.forms.produk.id : "",
    //             stock: parseInt(this.forms.stock)
    //           }
    //         });
    //         if (resolve) {
    //           resolve(addMatpro);
    //         } else {
    //           reject(addMatpro);
    //         }
    //       })
    //         .then(response => {
    //           if (response) {
    //             this.toastAlert(this.$t("alert.add"));
    //             this.closeModal();
    //             this.$apollo.queries.listRequestProposal.refetch();
    //           }
    //         })
    //         .catch(err => {
    //           this.errorAlert(err);
    //         });
    //     }
    //   }
    // },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await new Promise((resolve, reject) => {
              const delTanaman = this.$apollo.mutate({
                mutation: delProposalRequest,
                variables: {
                  id: id
                }
              });
              if (resolve) {
                resolve(delTanaman);
              } else {
                reject(delTanaman);
              }
            });
            if (response) {
              this.toastAlert("Berhasil menghapus data!");
              this.$apollo.queries.listRequestProposal.refetch();
            }
          } catch (err) {
            this.errorAlert(err);
          }
        }
      });
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/request-proposal/excel",
        {
          type: "sample",
          status: this.filterStatus.toLowerCase()
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `samplereq-${this.filterStatus.toLowerCase()}-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    this.filterStatus = this.statusProposals[2];
  }
};
</script>

<style lang="scss">
.req-data .table tbody tr {
  font-size: 15px;
  th {
    padding: 4px;
  }
  td {
    &:nth-child(2) {
      width: 3%;
    }
    &:nth-child(3) {
      width: 80%;
    }
    padding: 4px;
  }
}
.tbl-proposals tbody tr td {
  padding: 8px;
}
.custom-select {
  background-color: #fff;
  border-radius: 8px;
}
</style>
